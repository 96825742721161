import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import axios from 'axios';
import { COLORS } from '../../ui/variables';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Alert } from '@material-ui/lab';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import {
  makeStyles,
  createStyles,
  Theme,
  withStyles,
} from '@material-ui/core/styles';
import { getDaysArray, removeZero } from '../../utils/appointmentsModalHelpers';
import { BodyTiny } from '../../ui/TypographyStyles';
import Button from '../../ui/BaseButton';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputBase from '@material-ui/core/InputBase';
import Tooltip from '@material-ui/core/Tooltip';

const Container = styled.section`
  padding: 40px 20px;
`;

const Title = styled.h3`
  font-size: 32px;
  font-weight: 300;
  text-align: center;
  line-height: 40px;
  letter-spacing: 0.5px;
  strong {
    font-weight: 600;
  }
`;

const Calendar = styled.div`
  margin-top: 32px;
  margin-bottom: 40px;
`;

const DateContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const DateStyling = styled.div`
  font-size: 18px;
`;

const CTAButton = styled(Button)`
  padding: 12px 26px;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2px;
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 24px;
`;

const TextCTA = styled.span`
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 2px;
  display: block;
  margin-bottom: 24px;
  a {
    color: ${COLORS.BLACK};
    text-decoration: underline;
  }
`;

const Disclaimer = styled(BodyTiny)`
  width: fit-content;
  margin: 0 auto;
`;

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: '14px',
  },
})(Tooltip);

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    fontFamily: 'Gibson',
    fontSize: '16px',
    fontWeight: 600,
    margin: '12px 16px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
  },
  button: {
    marginRight: '12px',
    width: '32px',
    height: '32px',
  },
}));

const CustomInput = withStyles((theme: Theme) =>
  createStyles({
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontFamily: 'Gibson',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      '&:focus': {
        borderRadius: 4,
        borderColor: '#000000',
      },
    },
  })
)(InputBase);

function AppointmentsModal() {
  const classes = useStyles();
  const [locationId, setLocationId] = useState(7);
  const [location, setLocation] = useState(null);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);

  useEffect(() => {
    axios
      .get('https://sdwebsitedata.s3.amazonaws.com/availability.json', {
        headers: {
          'Access-Control-Allow-Origin': '*',
        },
      })
      .then(response => {
        setLocation(response.data[locationId][60]);
      });
  }, [locationId]);

  // date utils
  const currentDate = new Date();
  const futureDate = currentDate.setDate(new Date().getDate() + 30);
  const rangeArray = getDaysArray(new Date(), futureDate);
  const days = rangeArray.map(v => v.toISOString().slice(0, 10));

  const [currentDay, setCurrentDay] = useState(days[0]);

  useEffect(() => {
    setCurrentDay(days[currentDayIndex]);
  }, [currentDayIndex]);

  const nextDay = () => {
    setCurrentDayIndex(prevSate => prevSate + 1);
  };

  const prevDay = () => {
    setCurrentDayIndex(prevSate => prevSate - 1);
  };

  // to format date & time
  const timeNow = new Date().toTimeString().split(' ')[0];
  const getDate = new Date(`${currentDay}T${timeNow}Z`);
  const dateFormat = getDate.toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric',
  });

  return (
    <Container>
      <Title>
        Available <br /> <strong>Appointments in</strong>
      </Title>

      <FormControl variant='outlined' className={classes.formControl}>
        <Select
          labelId='locations-label'
          id='practice'
          value={locationId}
          onChange={event => setLocationId(event.target.value)}
          input={<CustomInput />}
        >
          <MenuItem value={7}>Gramercy</MenuItem>
          <MenuItem value={6}>Upper East Side</MenuItem>
          <MenuItem value={9}>Upper West Side</MenuItem>
          <MenuItem value={1}>West Village</MenuItem>
          <MenuItem value={8}>Williamsburg</MenuItem>
        </Select>
      </FormControl>

      <Calendar>
        <DateContainer>
          <DateStyling>
            <span>{dateFormat}</span>
          </DateStyling>
          <div>
            <IconButton
              size='small'
              aria-label='previous day'
              className={classes.button}
              disabled={currentDayIndex <= 0}
              onClick={prevDay}
            >
              <ChevronLeftIcon fontSize='small' />
            </IconButton>
            <IconButton
              size='small'
              aria-label='next day'
              disabled={currentDayIndex >= 30}
              onClick={nextDay}
            >
              <ChevronRightIcon fontSize='small' />
            </IconButton>
          </div>
        </DateContainer>
        <Grid container spacing={1}>
          {location && location[currentDay] ? (
            location[currentDay].map((time: string, index: React.Key) => {
              return (
                <Grid item key={index} xs={4} sm={2} md={4} lg={3}>
                  <CustomTooltip
                    arrow
                    placement='top'
                    title='Join below to book an appointment.'
                    enterTouchDelay={25}
                    leaveTouchDelay={1000}
                  >
                    <Typography variant='body1' className={classes.paper}>
                      {removeZero(time)}
                    </Typography>
                  </CustomTooltip>
                </Grid>
              );
            })
          ) : (
            <Grid item xs={12}>
              <Alert severity='info'>
                No available appointments on this day.
              </Alert>
            </Grid>
          )}
        </Grid>
      </Calendar>

      <CTAButton
        type='external'
        href={`${process.env.GATSBY_NEW_ONBOARDING_URL}signup?coupon=SD50IG`}
        color={COLORS.ORANGE}
      >
        Book Now
      </CTAButton>
      <TextCTA>
        Or call <a href='tel:+12129339044'>212.933.9044</a>
      </TextCTA>
      <Disclaimer>Availabilities will vary by appointment type.</Disclaimer>
    </Container>
  );
}

export default AppointmentsModal;
